@keyframes loading {
    from {
        opacity: 0.9;
    }

    to {
        opacity: 0.3;
    }
}

@keyframes slide-in {
    0% {
        opacity: 0;
        transform: translate(0, 20px);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes dots-animation {
    0% {
        content: '\00a0\00a0\00a0';
    }

    16.66% {
        content: '.\00a0\00a0';
    }

    33.33% {
        content: '..\00a0';
    }

    50% {
        content: '...';
    }

    66.66% {
        content: '..\00a0';
    }

    83.33% {
        content: '.\00a0\00a0';
    }

    100% {
        content: '\00a0\00a0\00a0';
    }
}
