@import './colors';

/**
 * Adds an outline when tabbing using keyboard
 */
@mixin keyboard-focus() {
    outline: 2px solid $color-azure-75;
    outline-offset: 1px;
    transition: none;
}

/* stylelint-disable */
/**
 * Sets specific colors used in dark theme mode
 */
@mixin theme-dark() {
    html[data-theme='dark'] & {
        @content;
    }

    @media (prefers-color-scheme: dark) {
        html:not([data-theme='light']) & {
            @content;
        }
    }
}
/* stylelint-enable */
