@import './mixins/remify';

$breakpoints: (
    phablet: 500px,
    tablet: 768px,
    desktop: 1024px,
    large: 1400px,
);

// Default values as SASS values.
$_color-white-100: #fff;
$_color-white-75: #f9fafb;
$_color-white-50: #f1f2f9;
$_color-white-25: #e7e9f3;
$_color-white-10: #dee1ed;

$_color-black-100: #080e2d;
$_color-black-75: #343a54;
$_color-black-50: #666c8a;
$_color-black-25: #a7a7be;
$_color-black-10: #d5d5e5;

$_color-mint-100: #53dac0;
$_color-mint-75: #57e5ca;
$_color-mint-50: #79ead5;
$_color-mint-25: #9aefdf;
$_color-mint-10: #e3fcf8;

$_color-violet-100: #462673;
$_color-violet-75: #5d3399;
$_color-violet-50: #7136c4;
$_color-violet-25: #a98dcf;
$_color-violet-10: #ede3fc;

$_color-azure-100: #0589d2;
$_color-azure-75: #0590dd;
$_color-azure-50: #37a6e4;
$_color-azure-25: #69bceb;
$_color-azure-10: #e3f4fc;

$_color-yellow-100: #f2c508;
$_color-yellow-75: #ffcf08;
$_color-yellow-50: #ffd939;
$_color-yellow-25: #ffe26b;
$_color-yellow-10: #fcf8e4;

$_color-orange-100: #e59e00;
$_color-orange-75: #f5a800;
$_color-orange-50: #f7b933;
$_color-orange-25: #f9cb66;
$_color-orange-10: #fcf4e3;

$_color-red-100: #e83837;
$_color-red-75: #f43b3a;
$_color-red-50: #f66261;
$_color-red-25: #f88989;
$_color-red-10: #fce3e3;

$_color-green-100: #18c767;
$_color-green-75: #1ad66e;
$_color-green-50: #48de8b;
$_color-green-25: #76e6a8;
$_color-green-10: #e3fcee;

$_color-blue-100: #003da5;
$_color-blue-75: #0040ae;
$_color-blue-50: #3360be;
$_color-blue-25: #668cce;
$_color-blue-10: #e3edfc;

$_color-lucky-point-100: #191954;
$_color-lucky-point-75: #1e1d62;
$_color-lucky-point-50: #4b4a81;
$_color-lucky-point-25: #7877a1;
$_color-lucky-point-10: #a5a5c0;

$_color-white-dark-100: #2a2b3c;
$_color-white-dark-75: #333347;
$_color-white-dark-50: #393951;
$_color-white-dark-25: #494969;
$_color-white-dark-10: #545478;

$_color-black-dark-100: #fff;
$_color-black-dark-75: #e1e1ea;
$_color-black-dark-50: #a9a9bc;
$_color-black-dark-25: #7e7e99;
$_color-black-dark-10: #6f6f86;

$_color-mint-dark-100: #73e2cd;
$_color-mint-dark-75: #5edec6;
$_color-mint-dark-50: #51b1a3;
$_color-mint-dark-25: #448581;
$_color-mint-dark-10: #2f3d4a;

$_color-violet-dark-100: #8a59cf;
$_color-violet-dark-75: #bb9ce3;
$_color-violet-dark-50: #8c76af;
$_color-violet-dark-25: #6b5d89;
$_color-violet-dark-10: #37354b;

$_color-azure-dark-100: #42a5db;
$_color-azure-dark-75: #2c9ad7;
$_color-azure-dark-50: #2b7eb0;
$_color-azure-dark-25: #2b6289;
$_color-azure-dark-10: #2a364b;

$_color-yellow-dark-100: #e9cb49;
$_color-yellow-dark-75: #e7c534;
$_color-yellow-dark-50: #b89e36;
$_color-yellow-dark-25: #887838;
$_color-yellow-dark-10: #3d3a3b;

$_color-orange-dark-100: #eab748;
$_color-orange-dark-75: #e7ad30;
$_color-orange-dark-50: #b88c33;
$_color-orange-dark-25: #886c36;
$_color-orange-dark-10: #3d383b;

$_color-red-dark-100: #f46c6c;
$_color-red-dark-75: #f25352;
$_color-red-dark-50: #c0494d;
$_color-red-dark-25: #8e3f47;
$_color-red-dark-10: #3e2f3e;

$_color-green-dark-100: #60dc97;
$_color-green-dark-75: #4bd78a;
$_color-green-dark-50: #43ac76;
$_color-green-dark-25: #3b8163;
$_color-green-dark-10: #2d3c44;

$_color-blue-dark-100: #75a0eb;
$_color-blue-dark-75: #5e91e8;
$_color-blue-dark-50: #5177bd;
$_color-blue-dark-25: #445e92;
$_color-blue-dark-10: #2f354d;

$_color-lucky-point-dark-100: #a7a7e2;
$_color-lucky-point-dark-75: #9191d5;
$_color-lucky-point-dark-50: #7070aa;
$_color-lucky-point-dark-25: #51517c;
$_color-lucky-point-dark-10: #212131;

$_color-deep-black: #15151e;

$_color-bg-page: $_color-white-25;
$_color-bg-default: $_color-white-50;
$_color-bg-default-accent: $_color-white-75;
$_color-bg-in-widget: $_color-white-50;
$_color-bg-in-widget-accent: $_color-white-25;
$_color-bg-transparent: rgba($_color-white-100, 0%);
$_color-bg-transparent-accent: rgba($_color-white-50, 80%);
$_color-bg-disabled: $_color-white-10;
$_color-bg-overlay: rgba($_color-deep-black, 65%);
$_color-primary: $_color-mint-75;
$_color-primary-accent: $_color-mint-100;
$_color-secondary: $_color-violet-75;
$_color-secondary-soft: $_color-violet-10;
$_color-border-default: $_color-white-10;
$_color-border-focus: $_color-azure-75;
$_color-success: $_color-green-75;
$_color-success-accent: $_color-green-100;
$_color-success-soft: $_color-green-10;
$_color-warning: $_color-orange-75;
$_color-warning-accent: $_color-orange-100;
$_color-warning-soft: $_color-orange-10;
$_color-danger: $_color-red-75;
$_color-danger-accent: $_color-red-100;
$_color-danger-soft: $_color-red-10;
$_color-info: $_color-azure-75;
$_color-info-accent: $_color-azure-100;
$_color-info-soft: $_color-azure-10;
$_color-neutral: $_color-white-10;
$_color-high-contrast: $_color-lucky-point-75;
$_color-high-contrast-accent: $_color-lucky-point-50;
$_color-text-headings: $_color-black-100;
$_color-text-static-high: $_color-black-100;
$_color-text-static-mid: $_color-black-75;
$_color-text-static-low: $_color-black-50;
$_color-text-base: $_color-black-50;
$_color-text-base-strong: $_color-black-75;
$_color-text-base-accent: $_color-black-100;
$_color-text-base-accent-alt: $_color-black-100;
$_color-text-base-soft: $_color-black-25;
$_color-text-disabled: $_color-black-25;
$_color-text-link: $_color-violet-75;
$_color-text-link-accent: $_color-violet-100;
$_color-text-link-visited: $_color-violet-75;
$_color-text-inverted: $_color-white-75;
$_color-text-inverted-accent: $_color-white-100;
$_color-text-fixed-clear: $_color-white-75;
$_color-text-fixed-clear-accent: $_color-white-100;
$_color-text-fixed-bold: $_color-black-75;
$_color-text-fixed-bold-accent: $_color-black-100;
$_color-text-highlight: $_color-black-100;
$_color-bg-text-highlight: $_color-yellow-25;

$_color-bg-page-dark: $_color-deep-black;
$_color-bg-default-dark: $_color-white-dark-100;
$_color-bg-default-accent-dark: $_color-white-dark-75;
$_color-bg-in-widget-dark: $_color-white-dark-50;
$_color-bg-in-widget-accent-dark: $_color-white-dark-25;
$_color-bg-transparent-dark: rgba($_color-white-100, 0%);
$_color-bg-transparent-accent-dark: rgba($_color-white-dark-50, 80%);
$_color-bg-disabled-dark: $_color-white-dark-25;
$_color-bg-overlay-dark: rgba($_color-deep-black, 65%);
$_color-primary-dark: $_color-mint-dark-75;
$_color-primary-accent-dark: $_color-mint-dark-100;
$_color-secondary-dark: $_color-violet-dark-75;
$_color-secondary-soft-dark: $_color-violet-dark-10;
$_color-border-default-dark: $_color-white-dark-50;
$_color-border-focus-dark: $_color-azure-dark-75;
$_color-success-dark: $_color-green-dark-75;
$_color-success-accent-dark: $_color-green-dark-100;
$_color-success-soft-dark: $_color-green-dark-10;
$_color-warning-dark: $_color-orange-dark-75;
$_color-warning-accent-dark: $_color-orange-dark-100;
$_color-warning-soft-dark: $_color-orange-dark-10;
$_color-danger-dark: $_color-red-dark-75;
$_color-danger-accent-dark: $_color-red-dark-100;
$_color-danger-soft-dark: $_color-red-dark-10;
$_color-info-dark: $_color-azure-dark-75;
$_color-info-accent-dark: $_color-azure-dark-100;
$_color-info-soft-dark: $_color-azure-dark-10;
$_color-neutral-dark: $_color-white-dark-25;
$_color-high-contrast-dark: $_color-lucky-point-dark-75;
$_color-high-contrast-accent-dark: $_color-lucky-point-dark-100;
$_color-text-headings-dark: $_color-black-dark-100;
$_color-text-static-high-dark: $_color-black-dark-100;
$_color-text-static-mid-dark: $_color-black-dark-75;
$_color-text-static-low-dark: $_color-black-dark-50;
$_color-text-base-dark: $_color-black-dark-50;
$_color-text-base-strong-dark: $_color-black-dark-75;
$_color-text-base-accent-dark: $_color-black-dark-100;
$_color-text-base-accent-alt-dark: $_color-mint-dark-75;
$_color-text-base-soft-dark: $_color-black-dark-25;
$_color-text-disabled-dark: $_color-black-dark-25;
$_color-text-link-dark: $_color-violet-dark-75;
$_color-text-link-accent-dark: $_color-violet-dark-100;
$_color-text-link-visited-dark: $_color-violet-dark-75;
$_color-text-inverted-dark: $_color-white-dark-75;
$_color-text-inverted-accent-dark: $_color-white-dark-100;
$_color-text-fixed-clear-dark: $_color-black-dark-75;
$_color-text-fixed-clear-accent-dark: $_color-black-dark-100;
$_color-text-fixed-bold-dark: $_color-white-dark-75;
$_color-text-fixed-bold-accent-dark: $_color-white-dark-100;
$_color-text-highlight-dark: $_color-black-dark-100;
$_color-bg-text-highlight-dark: $_color-yellow-dark-25;

$_font-family--heading: 'Hanken Grotesk', sans-serif;
$_font-family--content: 'Inter', sans-serif;

$_font-size--display: remify(32px);
$_font-size--heading-1: remify(24px);
$_font-size--heading-2: remify(22px);
$_font-size--heading-3: remify(20px);
$_font-size--heading-4: remify(18px);
$_font-size--heading-5: remify(16px);
$_font-size--subtitle: remify(14px);
$_font-size--body: remify(12px);
$_font-size--caption: remify(10px);
$_font-size--overline: remify(11px);

$_line-height--display: remify(48px);
$_line-height--heading-1: remify(36px);
$_line-height--heading-2: remify(32px);
$_line-height--heading-3: remify(30px);
$_line-height--heading-4: remify(26px);
$_line-height--heading-5: remify(24px);
$_line-height--subtitle: remify(20px);
$_line-height--body: remify(18px);
$_line-height--caption: remify(15px);
$_line-height--overline: remify(16px);

$_shadow-x-small--top: 0 -1px 1px rgba(10, 31, 68, 0.09);
$_shadow-x-small--bottom: 0 1px 1px rgba(10, 31, 68, 0.09);
$_shadow-x-small--center: 0 0 1px rgba(10, 31, 68, 0.09);
$_shadow-x-small--left: -1px 1px 1px rgba(10, 31, 68, 0.09);
$_shadow-x-small--right: 1px 1px 1px rgba(10, 31, 68, 0.09);

$_shadow-small--top: 0 -1px 3px rgba(10, 31, 68, 0.09);
$_shadow-small--bottom: 0 1px 3px rgba(10, 31, 68, 0.09);
$_shadow-small--center: 0 0 3px rgba(10, 31, 68, 0.09);
$_shadow-small--left: -1px 3px 3px rgba(10, 31, 68, 0.09);
$_shadow-small--right: 1px 3px 3px rgba(10, 31, 68, 0.09);

$_shadow--top: 0 0 1px rgba(10, 31, 68, 0.08), 0 -3px 4px rgba(10, 31, 68, 0.09);
$_shadow--bottom: 0 0 1px rgba(10, 31, 68, 0.08), 0 3px 4px rgba(10, 31, 68, 0.09);
$_shadow--center: 0 0 1px rgba(10, 31, 68, 0.08), 0 0 4px rgba(10, 31, 68, 0.09);
$_shadow--left: 0 0 1px rgba(10, 31, 68, 0.08), -3px 4px 4px rgba(10, 31, 68, 0.09);
$_shadow--right: 0 0 1px rgba(10, 31, 68, 0.08), 3px 4px 4px rgba(10, 31, 68, 0.09);

$_shadow-regular--top: 0 0 1px rgba(10, 31, 68, 0.08), 0 -3px 4px rgba(10, 31, 68, 0.09);
$_shadow-regular--bottom: 0 0 1px rgba(10, 31, 68, 0.08), 0 3px 4px rgba(10, 31, 68, 0.09);
$_shadow-regular--center: 0 0 1px rgba(10, 31, 68, 0.08), 0 0 4px rgba(10, 31, 68, 0.09);
$_shadow-regular--left: 0 0 1px rgba(10, 31, 68, 0.08), -3px 4px 4px rgba(10, 31, 68, 0.09);
$_shadow-regular--right: 0 0 1px rgba(10, 31, 68, 0.08), 3px 4px 4px rgba(10, 31, 68, 0.09);

$_shadow-medium-soft--top: 0 0 1px rgba(10, 31, 68, 0.08), 0 -8px 10px rgba(10, 31, 68, 0.09);
$_shadow-medium-soft--bottom: 0 0 1px rgba(10, 31, 68, 0.08), 0 8px 10px rgba(10, 31, 68, 0.09);
$_shadow-medium-soft--center: 0 0 1px rgba(10, 31, 68, 0.08), 0 0 10px rgba(10, 31, 68, 0.09);
$_shadow-medium-soft--left: 0 0 1px rgba(10, 31, 68, 0.08), -8px 10px 10px rgba(10, 31, 68, 0.09);
$_shadow-medium-soft--right: 0 0 1px rgba(10, 31, 68, 0.08), 8px 10px 10px rgba(10, 31, 68, 0.09);

$_shadow-medium--top: 0 0 1px rgba(10, 31, 68, 0.08), 0 -8px 16px rgba(10, 31, 68, 0.16);
$_shadow-medium--bottom: 0 0 1px rgba(10, 31, 68, 0.08), 0 8px 16px rgba(10, 31, 68, 0.16);
$_shadow-medium--center: 0 0 1px rgba(10, 31, 68, 0.08), 0 0 16px rgba(10, 31, 68, 0.16);
$_shadow-medium--left: 0 0 1px rgba(10, 31, 68, 0.08), -8px 16px 16px rgba(10, 31, 68, 0.16);
$_shadow-medium--right: 0 0 1px rgba(10, 31, 68, 0.08), 8px 16px 16px rgba(10, 31, 68, 0.16);

$_shadow-large-soft--top: 0 0 1px rgba(10, 31, 68, 0.08), 0 -26px 26px rgba(10, 31, 68, 0.09);
$_shadow-large-soft--bottom: 0 0 1px rgba(10, 31, 68, 0.08), 0 26px 26px rgba(10, 31, 68, 0.09);
$_shadow-large-soft--center: 0 0 1px rgba(10, 31, 68, 0.08), 0 0 26px rgba(10, 31, 68, 0.09);
$_shadow-large-soft--left: 0 0 1px rgba(10, 31, 68, 0.08), -26px 26px 26px rgba(10, 31, 68, 0.09);
$_shadow-large-soft--right: 0 0 1px rgba(10, 31, 68, 0.08), 26px 26px 26px rgba(10, 31, 68, 0.09);

$_shadow-large--top: 0 0 1px rgba(10, 31, 68, 0.08), 0 -16px 48px rgba(10, 31, 68, 0.16);
$_shadow-large--bottom: 0 0 1px rgba(10, 31, 68, 0.08), 0 16px 48px rgba(10, 31, 68, 0.16);
$_shadow-large--center: 0 0 1px rgba(10, 31, 68, 0.08), 0 0 48px rgba(10, 31, 68, 0.16);
$_shadow-large--left: 0 0 1px rgba(10, 31, 68, 0.08), -16px 48px 48px rgba(10, 31, 68, 0.16);
$_shadow-large--right: 0 0 1px rgba(10, 31, 68, 0.08), 16px 48px 48px rgba(10, 31, 68, 0.16);

$_shadow-x-small-dark--top: 0 -1px 1px rgba(0, 0, 0, 0.3);
$_shadow-x-small-dark--bottom: 0 1px 1px rgba(0, 0, 0, 0.3);
$_shadow-x-small-dark--center: 0 0 1px rgba(0, 0, 0, 0.3);
$_shadow-x-small-dark--left: -1px 1px 1px rgba(0, 0, 0, 0.3);
$_shadow-x-small-dark--right: 1px 1px 1px rgba(0, 0, 0, 0.3);

$_shadow-small-dark--top: 0 -1px 3px rgba(0, 0, 0, 0.3);
$_shadow-small-dark--bottom: 0 1px 3px rgba(0, 0, 0, 0.3);
$_shadow-small-dark--center: 0 0 3px rgba(0, 0, 0, 0.3);
$_shadow-small-dark--left: -1px 3px 3px rgba(0, 0, 0, 0.3);
$_shadow-small-dark--right: 1px 3px 3px rgba(0, 0, 0, 0.3);

$_shadow-dark--top: 0 0 1px rgba(0, 0, 0, 0.24), 0 -3px 4px rgba(0, 0, 0, 0.27);
$_shadow-dark--bottom: 0 0 1px rgba(0, 0, 0, 0.24), 0 3px 4px rgba(0, 0, 0, 0.27);
$_shadow-dark--center: 0 0 1px rgba(0, 0, 0, 0.24), 0 0 4px rgba(0, 0, 0, 0.27);
$_shadow-dark--left: 0 0 1px rgba(0, 0, 0, 0.24), -3px 4px 4px rgba(0, 0, 0, 0.27);
$_shadow-dark--right: 0 0 1px rgba(0, 0, 0, 0.24), 3px 4px 4px rgba(0, 0, 0, 0.27);

$_shadow-regular-dark--top: 0 0 1px rgba(0, 0, 0, 0.24), 0 -3px 4px rgba(0, 0, 0, 0.27);
$_shadow-regular-dark--bottom: 0 0 1px rgba(0, 0, 0, 0.24), 0 3px 4px rgba(0, 0, 0, 0.27);
$_shadow-regular-dark--center: 0 0 1px rgba(0, 0, 0, 0.24), 0 0 4px rgba(0, 0, 0, 0.27);
$_shadow-regular-dark--left: 0 0 1px rgba(0, 0, 0, 0.24), -3px 4px 4px rgba(0, 0, 0, 0.27);
$_shadow-regular-dark--right: 0 0 1px rgba(0, 0, 0, 0.24), 3px 4px 4px rgba(0, 0, 0, 0.27);

$_shadow-medium-soft-dark--top: 0 0 1px rgba(0, 0, 0, 0.24), 0 -9px 10px rgba(0, 0, 0, 0.27);
$_shadow-medium-soft-dark--bottom: 0 0 1px rgba(0, 0, 0, 0.24), 0 8px 10px rgba(0, 0, 0, 0.27);
$_shadow-medium-soft-dark--center: 0 0 1px rgba(0, 0, 0, 0.24), 0 0 10px rgba(0, 0, 0, 0.27);
$_shadow-medium-soft-dark--left: 0 0 1px rgba(0, 0, 0, 0.24), -8px 10px 10px rgba(0, 0, 0, 0.27);
$_shadow-medium-soft-dark--right: 0 0 1px rgba(0, 0, 0, 0.24), 8px 10px 10px rgba(0, 0, 0, 0.27);

$_shadow-medium-dark--top: 0 0 1px rgba(0, 0, 0, 0.28), 0 -8px 16px rgba(0, 0, 0, 0.56);
$_shadow-medium-dark--bottom: 0 0 1px rgba(0, 0, 0, 0.28), 0 8px 16px rgba(0, 0, 0, 0.56);
$_shadow-medium-dark--center: 0 0 1px rgba(0, 0, 0, 0.28), 0 0 16px rgba(0, 0, 0, 0.56);
$_shadow-medium-dark--left: 0 0 1px rgba(0, 0, 0, 0.28), -8px 16px 16px rgba(0, 0, 0, 0.56);
$_shadow-medium-dark--right: 0 0 1px rgba(0, 0, 0, 0.28), 8px 16px 16px rgba(0, 0, 0, 0.56);

$_shadow-large-soft-dark--top: 0 0 1px rgba(0, 0, 0, 0.24), 0 -26px 26px rgba(0, 0, 0, 0.27);
$_shadow-large-soft-dark--bottom: 0 0 1px rgba(0, 0, 0, 0.24), 0 26px 26px rgba(0, 0, 0, 0.27);
$_shadow-large-soft-dark--center: 0 0 1px rgba(0, 0, 0, 0.24), 0 0 26px rgba(0, 0, 0, 0.27);
$_shadow-large-soft-dark--left: 0 0 1px rgba(0, 0, 0, 0.24), -26px 26px 26px rgba(0, 0, 0, 0.27);
$_shadow-large-soft-dark--right: 0 0 1px rgba(0, 0, 0, 0.24), 26px 26px 26px rgba(0, 0, 0, 0.27);

$_shadow-large-dark--top: 0 0 1px rgba(0, 0, 0, 0.32), 0 -16px 48px rgba(0, 0, 0, 0.6);
$_shadow-large-dark--bottom: 0 0 1px rgba(0, 0, 0, 0.32), 0 16px 48px rgba(0, 0, 0, 0.6);
$_shadow-large-dark--center: 0 0 1px rgba(0, 0, 0, 0.32), 0 0 48px rgba(0, 0, 0, 0.6);
$_shadow-large-dark--left: 0 0 1px rgba(0, 0, 0, 0.32), -16px 48px 48px rgba(0, 0, 0, 0.6);
$_shadow-large-dark--right: 0 0 1px rgba(0, 0, 0, 0.32), 16px 48px 48px rgba(0, 0, 0, 0.6);

$_radius--small: 4px;
$_radius: 8px;
$_radius--large: 12px;

$_space--xxx-large: 32px;
$_space--xx-large: 24px;
$_space--x-large: 20px;
$_space--large: 16px;
$_space--medium: 12px;
$_space: 10px;
$_space--small: 8px;
$_space--x-small: 4px;
$_space--xx-small: 2px;
$_space--xxx-small: 1px;

$_transition-delay: 0.2s;

$_icon-size: remify(24px);

$_page-max-width: 1140px;
$_page-max-width--small: 650px;
$_sidebar-width: 280px;
$_top-nav-bar-height: remify(56px);

// CSS Variable definitions for use in components
$color-white-100: var(--color-white-100, #{$_color-white-100});
$color-white-75: var(--color-white-75, #{$_color-white-75});
$color-white-50: var(--color-white-50, #{$_color-white-50});
$color-white-25: var(--color-white-25, #{$_color-white-25});
$color-white-10: var(--color-white-10, #{$_color-white-10});

$color-black-100: var(--color-black-100, #{$_color-black-100});
$color-black-75: var(--color-black-75, #{$_color-black-75});
$color-black-50: var(--color-black-50, #{$_color-black-50});
$color-black-25: var(--color-black-25, #{$_color-black-25});
$color-black-10: var(--color-black-10, #{$_color-black-10});

$color-mint-100: var(--color-mint-100, #{$_color-mint-100});
$color-mint-75: var(--color-mint-75, #{$_color-mint-75});
$color-mint-50: var(--color-mint-50, #{$_color-mint-50});
$color-mint-25: var(--color-mint-25, #{$_color-mint-25});
$color-mint-10: var(--color-mint-10, #{$_color-mint-10});

$color-violet-100: var(--color-violet-100, #{$_color-violet-100});
$color-violet-75: var(--color-violet-75, #{$_color-violet-75});
$color-violet-50: var(--color-violet-50, #{$_color-violet-50});
$color-violet-25: var(--color-violet-25, #{$_color-violet-25});
$color-violet-10: var(--color-violet-10, #{$_color-violet-10});

$color-azure-100: var(--color-azure-100, #{$_color-azure-100});
$color-azure-75: var(--color-azure-75, #{$_color-azure-75});
$color-azure-50: var(--color-azure-50, #{$_color-azure-50});
$color-azure-25: var(--color-azure-25, #{$_color-azure-25});
$color-azure-10: var(--color-azure-10, #{$_color-azure-10});

$color-yellow-100: var(--color-yellow-100, #{$_color-yellow-100});
$color-yellow-75: var(--color-yellow-75, #{$_color-yellow-75});
$color-yellow-50: var(--color-yellow-50, #{$_color-yellow-50});
$color-yellow-25: var(--color-yellow-25, #{$_color-yellow-25});
$color-yellow-10: var(--color-yellow-10, #{$_color-yellow-10});

$color-orange-100: var(--color-orange-100, #{$_color-orange-100});
$color-orange-75: var(--color-orange-75, #{$_color-orange-75});
$color-orange-50: var(--color-orange-50, #{$_color-orange-50});
$color-orange-25: var(--color-orange-25, #{$_color-orange-25});
$color-orange-10: var(--color-orange-10, #{$_color-orange-10});

$color-red-100: var(--color-red-100, #{$_color-red-100});
$color-red-75: var(--color-red-75, #{$_color-red-75});
$color-red-50: var(--color-red-50, #{$_color-red-50});
$color-red-25: var(--color-red-25, #{$_color-red-25});
$color-red-10: var(--color-red-10, #{$_color-red-10});

$color-green-100: var(--color-green-100, #{$_color-green-100});
$color-green-75: var(--color-green-75, #{$_color-green-75});
$color-green-50: var(--color-green-50, #{$_color-green-50});
$color-green-25: var(--color-green-25, #{$_color-green-25});
$color-green-10: var(--color-green-10, #{$_color-green-10});

$color-blue-100: var(--color-blue-100, #{$_color-blue-100});
$color-blue-75: var(--color-blue-75, #{$_color-blue-75});
$color-blue-50: var(--color-blue-50, #{$_color-blue-50});
$color-blue-25: var(--color-blue-25, #{$_color-blue-25});
$color-blue-10: var(--color-blue-10, #{$_color-blue-10});

$color-lucky-point-100: var(--color-lucky-point-100, #{$_color-lucky-point-100});
$color-lucky-point-75: var(--color-lucky-point-75, #{$_color-lucky-point-75});
$color-lucky-point-50: var(--color-lucky-point-50, #{$_color-lucky-point-50});
$color-lucky-point-25: var(--color-lucky-point-25, #{$_color-lucky-point-25});
$color-lucky-point-10: var(--color-lucky-point-10, #{$_color-lucky-point-10});

$color-white-dark-100: var(--color-white-dark-100, #{$_color-white-dark-100});
$color-white-dark-75: var(--color-white-dark-75, #{$_color-white-dark-75});
$color-white-dark-50: var(--color-white-dark-50, #{$_color-white-dark-50});
$color-white-dark-25: var(--color-white-dark-25, #{$_color-white-dark-25});
$color-white-dark-10: var(--color-white-dark-10, #{$_color-white-dark-10});

$color-black-dark-100: var(--color-black-dark-100, #{$_color-black-dark-100});
$color-black-dark-75: var(--color-black-dark-75, #{$_color-black-dark-75});
$color-black-dark-50: var(--color-black-dark-50, #{$_color-black-dark-50});
$color-black-dark-25: var(--color-black-dark-25, #{$_color-black-dark-25});
$color-black-dark-10: var(--color-black-dark-10, #{$_color-black-dark-10});

$color-mint-dark-100: var(--color-mint-dark-100, #{$_color-mint-dark-100});
$color-mint-dark-75: var(--color-mint-dark-75, #{$_color-mint-dark-75});
$color-mint-dark-50: var(--color-mint-dark-50, #{$_color-mint-dark-50});
$color-mint-dark-25: var(--color-mint-dark-25, #{$_color-mint-dark-25});
$color-mint-dark-10: var(--color-mint-dark-10, #{$_color-mint-dark-10});

$color-violet-dark-100: var(--color-violet-dark-100, #{$_color-violet-dark-100});
$color-violet-dark-75: var(--color-violet-dark-75, #{$_color-violet-dark-75});
$color-violet-dark-50: var(--color-violet-dark-50, #{$_color-violet-dark-50});
$color-violet-dark-25: var(--color-violet-dark-25, #{$_color-violet-dark-25});
$color-violet-dark-10: var(--color-violet-dark-10, #{$_color-violet-dark-10});

$color-azure-dark-100: var(--color-azure-dark-100, #{$_color-azure-dark-100});
$color-azure-dark-75: var(--color-azure-dark-75, #{$_color-azure-dark-75});
$color-azure-dark-50: var(--color-azure-dark-50, #{$_color-azure-dark-50});
$color-azure-dark-25: var(--color-azure-dark-25, #{$_color-azure-dark-25});
$color-azure-dark-10: var(--color-azure-dark-10, #{$_color-azure-dark-10});

$color-yellow-dark-100: var(--color-yellow-dark-100, #{$_color-yellow-dark-100});
$color-yellow-dark-75: var(--color-yellow-dark-75, #{$_color-yellow-dark-75});
$color-yellow-dark-50: var(--color-yellow-dark-50, #{$_color-yellow-dark-50});
$color-yellow-dark-25: var(--color-yellow-dark-25, #{$_color-yellow-dark-25});
$color-yellow-dark-10: var(--color-yellow-dark-10, #{$_color-yellow-dark-10});

$color-orange-dark-100: var(--color-orange-dark-100, #{$_color-orange-dark-100});
$color-orange-dark-75: var(--color-orange-dark-75, #{$_color-orange-dark-75});
$color-orange-dark-50: var(--color-orange-dark-50, #{$_color-orange-dark-50});
$color-orange-dark-25: var(--color-orange-dark-25, #{$_color-orange-dark-25});
$color-orange-dark-10: var(--color-orange-dark-10, #{$_color-orange-dark-10});

$color-red-dark-100: var(--color-red-dark-100, #{$_color-red-dark-100});
$color-red-dark-75: var(--color-red-dark-75, #{$_color-red-dark-75});
$color-red-dark-50: var(--color-red-dark-50, #{$_color-red-dark-50});
$color-red-dark-25: var(--color-red-dark-25, #{$_color-red-dark-25});
$color-red-dark-10: var(--color-red-dark-10, #{$_color-red-dark-10});

$color-green-dark-100: var(--color-green-dark-100, #{$_color-green-dark-100});
$color-green-dark-75: var(--color-green-dark-75, #{$_color-green-dark-75});
$color-green-dark-50: var(--color-green-dark-50, #{$_color-green-dark-50});
$color-green-dark-25: var(--color-green-dark-25, #{$_color-green-dark-25});
$color-green-dark-10: var(--color-green-dark-10, #{$_color-green-dark-10});

$color-blue-dark-100: var(--color-blue-dark-100, #{$_color-blue-dark-100});
$color-blue-dark-75: var(--color-blue-dark-75, #{$_color-blue-dark-75});
$color-blue-dark-50: var(--color-blue-dark-50, #{$_color-blue-dark-50});
$color-blue-dark-25: var(--color-blue-dark-25, #{$_color-blue-dark-25});
$color-blue-dark-10: var(--color-blue-dark-10, #{$_color-blue-dark-10});

$color-lucky-point-dark-100: var(--color-lucky-point-dark-100, #{$_color-lucky-point-dark-100});
$color-lucky-point-dark-75: var(--color-lucky-point-dark-75, #{$_color-lucky-point-dark-75});
$color-lucky-point-dark-50: var(--color-lucky-point-dark-50, #{$_color-lucky-point-dark-50});
$color-lucky-point-dark-25: var(--color-lucky-point-dark-25, #{$_color-lucky-point-dark-25});
$color-lucky-point-dark-10: var(--color-lucky-point-dark-10, #{$_color-lucky-point-dark-10});

$color-deep-black: var(--color-deep-black, #{$_color-deep-black});

$color-bg-page: var(--color-bg-page, #{$_color-bg-page});
$color-bg-default: var(--color-bg-default, #{$_color-bg-default});
$color-bg-default-accent: var(--color-bg-default-accent, #{$_color-bg-default-accent});
$color-bg-in-widget: var(--color-bg-in-widget, #{$_color-bg-in-widget});
$color-bg-in-widget-accent: var(--color-bg-in-widget-accent, #{$_color-bg-in-widget-accent});
$color-bg-transparent: var(--color-bg-transparent, #{$_color-bg-transparent});
$color-bg-transparent-accent: var(--color-bg-transparent-accent, #{$_color-bg-transparent-accent});
$color-bg-disabled: var(--color-bg-disabled, #{$_color-bg-disabled});
$color-bg-overlay: var(--color-bg-overlay, #{$_color-bg-overlay});
$color-primary: var(--color-primary, #{$_color-primary});
$color-primary-accent: var(--color-primary-accent, #{$_color-primary-accent});
$color-secondary: var(--color-secondary, #{$_color-secondary});
$color-secondary-soft: var(--color-secondary-soft, #{$_color-secondary-soft});
$color-border-default: var(--color-border-default, #{$_color-border-default});
$color-border-focus: var(--color-border-focus, #{$_color-border-focus});
$color-success: var(--color-success, #{$_color-success});
$color-success-accent: var(--color-success-accent, #{$_color-success-accent});
$color-success-soft: var(--color-success-soft, #{$_color-success-soft});
$color-warning: var(--color-warning, #{$_color-warning});
$color-warning-accent: var(--color-warning-accent, #{$_color-warning-accent});
$color-warning-soft: var(--color-warning-soft, #{$_color-warning-soft});
$color-danger: var(--color-danger, #{$_color-danger});
$color-danger-accent: var(--color-danger-accent, #{$_color-danger-accent});
$color-danger-soft: var(--color-danger-soft, #{$_color-danger-soft});
$color-info: var(--color-info, #{$_color-info});
$color-info-accent: var(--color-info-accent, #{$_color-info-accent});
$color-info-soft: var(--color-info-soft, #{$_color-info-soft});
$color-neutral: var(--color-neutral, #{$_color-neutral});
$color-high-contrast: var(--color-high-contrast, #{$_color-high-contrast});
$color-high-contrast-accent: var(--color-high-contrast-accent, #{$_color-high-contrast-accent});
$color-text-headings: var(--color-text-headings, #{$_color-text-headings});
$color-text-static-high: var(--color-text-static-high, #{$_color-text-static-high});
$color-text-static-mid: var(--color-text-static-mid, #{$_color-text-static-mid});
$color-text-static-low: var(--color-text-static-low, #{$_color-text-static-low});
$color-text-base: var(--color-text-base, #{$_color-text-base});
$color-text-base-strong: var(--color-text-base-strong, #{$_color-text-base-strong});
$color-text-base-accent: var(--color-text-base-accent, #{$_color-text-base-accent});
$color-text-base-accent-alt: var(--color-text-base-accent-alt, #{$_color-text-base-accent-alt});
$color-text-base-soft: var(--color-text-base-soft, #{$_color-text-base-soft});
$color-text-disabled: var(--color-text-disabled, #{$_color-text-disabled});
$color-text-link: var(--color-text-link, #{$_color-text-link});
$color-text-link-accent: var(--color-text-link-accent, #{$_color-text-link-accent});
$color-text-link-visited: var(--color-text-link-visited, #{$_color-text-link-visited});
$color-text-inverted: var(--color-text-inverted, #{$_color-text-inverted});
$color-text-inverted-accent: var(--color-text-inverted-accent, #{$_color-text-inverted-accent});
$color-text-fixed-clear: var(--color-text-fixed-clear, #{$_color-text-fixed-clear});
$color-text-fixed-clear-accent: var(
    --color-text-fixed-clear-accent,
    #{$_color-text-fixed-clear-accent}
);
$color-text-fixed-bold: var(--color-text-fixed-bold, #{$_color-text-fixed-bold});
$color-text-fixed-bold-accent: var(
    --color-text-fixed-bold-accent,
    #{$_color-text-fixed-bold-accent}
);
$color-text-highlight: var(--color-text-highlight, #{$_color-text-highlight});
$color-bg-text-highlight: var(--color-bg-text-highlight, #{$_color-bg-text-highlight});

$color-bg-page-dark: var(--color-bg-page-dark, #{$_color-bg-page-dark});
$color-bg-default-dark: var(--color-bg-default-dark, #{$_color-bg-default-dark});
$color-bg-default-accent-dark: var(
    --color-bg-default-accent-dark,
    #{$_color-bg-default-accent-dark}
);
$color-bg-in-widget-dark: var(--color-bg-in-widget-dark, #{$_color-bg-in-widget-dark});
$color-bg-in-widget-accent-dark: var(
    --color-bg-in-widget-accent-dark,
    #{$_color-bg-in-widget-accent-dark}
);
$color-bg-transparent-dark: var(--color-bg-transparent-dark, #{$_color-bg-transparent-dark});
$color-bg-transparent-accent-dark: var(
    --color-bg-transparent-accent-dark,
    #{$_color-bg-transparent-accent-dark}
);
$color-bg-disabled-dark: var(--color-bg-disabled-dark, #{$_color-bg-disabled-dark});
$color-bg-overlay-dark: var(--color-bg-overlay-dark, #{$_color-bg-overlay-dark});
$color-primary-dark: var(--color-primary-dark, #{$_color-primary-dark});
$color-primary-accent-dark: var(--color-primary-accent-dark, #{$_color-primary-accent-dark});
$color-secondary-dark: var(--color-secondary-dark, #{$_color-secondary-dark});
$color-secondary-soft-dark: var(--color-secondary-soft-dark, #{$_color-secondary-soft-dark});
$color-border-default-dark: var(--color-border-default-dark, #{$_color-border-default-dark});
$color-border-focus-dark: var(--color-border-focus-dark, #{$_color-border-focus-dark});
$color-success-dark: var(--color-success-dark, #{$_color-success-dark});
$color-success-accent-dark: var(--color-success-accent-dark, #{$_color-success-accent-dark});
$color-success-soft-dark: var(--color-success-soft-dark, #{$_color-success-soft-dark});
$color-warning-dark: var(--color-warning-dark, #{$_color-warning-dark});
$color-warning-accent-dark: var(--color-warning-accent-dark, #{$_color-warning-accent-dark});
$color-warning-soft-dark: var(--color-warning-soft-dark, #{$_color-warning-soft-dark});
$color-danger-dark: var(--color-danger-dark, #{$_color-danger-dark});
$color-danger-accent-dark: var(--color-danger-accent-dark, #{$_color-danger-accent-dark});
$color-danger-soft-dark: var(--color-danger-soft-dark, #{$_color-danger-soft-dark});
$color-info-dark: var(--color-info-dark, #{$_color-info-dark});
$color-info-accent-dark: var(--color-info-accent-dark, #{$_color-info-accent-dark});
$color-info-soft-dark: var(--color-info-soft-dark, #{$_color-info-soft-dark});
$color-neutral-dark: var(--color-neutral-dark, #{$_color-neutral-dark});
$color-high-contrast-dark: var(--color-high-contrast-dark, #{$_color-high-contrast-dark});
$color-high-contrast-accent-dark: var(
    --color-high-contrast-accent-dark,
    #{$_color-high-contrast-accent-dark}
);
$color-text-headings-dark: var(--color-text-headings-dark, #{$_color-text-headings-dark});
$color-text-static-high-dark: var(--color-text-static-high-dark, #{$_color-text-static-high-dark});
$color-text-static-mid-dark: var(--color-text-static-mid-dark, #{$_color-text-static-mid-dark});
$color-text-static-low-dark: var(--color-text-static-low-dark, #{$_color-text-static-low-dark});
$color-text-base-dark: var(--color-text-base-dark, #{$_color-text-base-dark});
$color-text-base-strong-dark: var(--color-text-base-strong-dark, #{$_color-text-base-strong-dark});
$color-text-base-accent-dark: var(--color-text-base-accent-dark, #{$_color-text-base-accent-dark});
$color-text-base-accent-alt-dark: var(
    --color-text-base-accent-alt-dark,
    #{$_color-text-base-accent-alt-dark}
);
$color-text-base-soft-dark: var(--color-text-base-soft-dark, #{$_color-text-base-soft-dark});
$color-text-disabled-dark: var(--color-text-disabled-dark, #{$_color-text-disabled-dark});
$color-text-link-dark: var(--color-text-link-dark, #{$_color-text-link-dark});
$color-text-link-accent-dark: var(--color-text-link-accent-dark, #{$_color-text-link-accent-dark});
$color-text-link-visited-dark: var(
    --color-text-link-visited-dark,
    #{$_color-text-link-visited-dark}
);
$color-text-inverted-dark: var(--color-text-inverted-dark, #{$_color-text-inverted-dark});
$color-text-inverted-accent-dark: var(
    --color-text-inverted-accent-dark,
    #{$_color-text-inverted-accent-dark}
);
$color-text-fixed-clear-dark: var(--color-text-fixed-clear-dark, #{$_color-text-fixed-clear-dark});
$color-text-fixed-clear-accent-dark: var(
    --color-text-fixed-clear-accent-dark,
    #{$_color-text-fixed-clear-accent-dark}
);
$color-text-fixed-bold-dark: var(--color-text-fixed-bold-dark, #{$_color-text-fixed-bold-dark});
$color-text-fixed-bold-accent-dark: var(
    --color-text-fixed-bold-accent-dark,
    #{$_color-text-fixed-bold-accent-dark}
);
$color-text-highlight-dark: var(--color-text-highlight-dark, #{$_color-text-highlight-dark});
$color-bg-text-highlight-dark: var(
    --color-bg-text-highlight-dark,
    #{$_color-bg-text-highlight-dark}
);

$font-family--heading: var(--font-family--heading, #{$_font-family--heading});
$font-family--content: var(--font-family--content, #{$_font-family--content});

$font-size--display: var(--font-size--display, #{$_font-size--display});
$font-size--heading-1: var(--font-size--heading-1, #{$_font-size--heading-1});
$font-size--heading-2: var(--font-size--heading-2, #{$_font-size--heading-2});
$font-size--heading-3: var(--font-size--heading-3, #{$_font-size--heading-3});
$font-size--heading-4: var(--font-size--heading-4, #{$_font-size--heading-4});
$font-size--heading-5: var(--font-size--heading-5, #{$_font-size--heading-5});
$font-size--subtitle: var(--font-size--subtitle, #{$_font-size--subtitle});
$font-size--body: var(--font-size--body, #{$_font-size--body});
$font-size--caption: var(--font-size--caption, #{$_font-size--caption});
$font-size--overline: var(--font-size--overline, #{$_font-size--overline});

$line-height--display: var(--line-height--display, #{$_line-height--display});
$line-height--heading-1: var(--line-height--heading-1, #{$_line-height--heading-1});
$line-height--heading-2: var(--line-height--heading-2, #{$_line-height--heading-2});
$line-height--heading-3: var(--line-height--heading-3, #{$_line-height--heading-3});
$line-height--heading-4: var(--line-height--heading-4, #{$_line-height--heading-4});
$line-height--heading-5: var(--line-height--heading-5, #{$_line-height--heading-5});
$line-height--subtitle: var(--line-height--subtitle, #{$_line-height--subtitle});
$line-height--body: var(--line-height--body, #{$_line-height--body});
$line-height--caption: var(--line-height--caption, #{$_line-height--caption});
$line-height--overline: var(--line-height--overline, #{$_line-height--overline});

$shadow-x-small--top: var(--shadow-x-small--top, #{$_shadow-x-small--top});
$shadow-x-small--bottom: var(--shadow-x-small--bottom, #{$_shadow-x-small--bottom});
$shadow-x-small--center: var(--shadow-x-small--center, #{$_shadow-x-small--center});
$shadow-x-small--left: var(--shadow-x-small--left, #{$_shadow-x-small--left});
$shadow-x-small--right: var(--shadow-x-small--right, #{$_shadow-x-small--right});

$shadow-small--top: var(--shadow-small--top, #{$_shadow-small--top});
$shadow-small--bottom: var(--shadow-small--bottom, #{$_shadow-small--bottom});
$shadow-small--center: var(--shadow-small--center, #{$_shadow-small--center});
$shadow-small--left: var(--shadow-small--left, #{$_shadow-small--left});
$shadow-small--right: var(--shadow-small--right, #{$_shadow-small--right});

$shadow--top: var(--shadow--top, #{$_shadow--top});
$shadow--bottom: var(--shadow--bottom, #{$_shadow--bottom});
$shadow--center: var(--shadow--center, #{$_shadow--center});
$shadow--left: var(--shadow--left, #{$_shadow--left});
$shadow--right: var(--shadow--right, #{$_shadow--right});

$shadow-regular--top: var(--shadow-regular--top, #{$_shadow-regular--top});
$shadow-regular--bottom: var(--shadow-regular--bottom, #{$_shadow-regular--bottom});
$shadow-regular--center: var(--shadow-regular--center, #{$_shadow-regular--center});
$shadow-regular--left: var(--shadow-regular--left, #{$_shadow-regular--left});
$shadow-regular--right: var(--shadow-regular--right, #{$_shadow-regular--right});

$shadow-medium-soft--top: var(--shadow-medium-soft--top, #{$_shadow-medium-soft--top});
$shadow-medium-soft--bottom: var(--shadow-medium-soft--bottom, #{$_shadow-medium-soft--bottom});
$shadow-medium-soft--center: var(--shadow-medium-soft--center, #{$_shadow-medium-soft--center});
$shadow-medium-soft--left: var(--shadow-medium-soft--left, #{$_shadow-medium-soft--left});
$shadow-medium-soft--right: var(--shadow-medium-soft--right, #{$_shadow-medium-soft--right});

$shadow-medium--top: var(--shadow-medium--top, #{$_shadow-medium--top});
$shadow-medium--bottom: var(--shadow-medium--bottom, #{$_shadow-medium--bottom});
$shadow-medium--center: var(--shadow-medium--center, #{$_shadow-medium--center});
$shadow-medium--left: var(--shadow-medium--left, #{$_shadow-medium--left});
$shadow-medium--right: var(--shadow-medium--right, #{$_shadow-medium--right});

$shadow-large-soft--top: var(--shadow-large-soft--top, #{$_shadow-large-soft--top});
$shadow-large-soft--bottom: var(--shadow-large-soft--bottom, #{$_shadow-large-soft--bottom});
$shadow-large-soft--center: var(--shadow-large-soft--center, #{$_shadow-large-soft--center});
$shadow-large-soft--left: var(--shadow-large-soft--left, #{$_shadow-large-soft--left});
$shadow-large-soft--right: var(--shadow-large-soft--right, #{$_shadow-large-soft--right});

$shadow-large--top: var(--shadow-large--top, #{$_shadow-large--top});
$shadow-large--bottom: var(--shadow-large--bottom, #{$_shadow-large--bottom});
$shadow-large--center: var(--shadow-large--center, #{$_shadow-large--center});
$shadow-large--left: var(--shadow-large--left, #{$_shadow-large--left});
$shadow-large--right: var(--shadow-large--right, #{$_shadow-large--right});

$shadow-x-small-dark--top: var(--shadow-x-small-dark--top, #{$_shadow-x-small-dark--top});
$shadow-x-small-dark--bottom: var(--shadow-x-small-dark--bottom, #{$_shadow-x-small-dark--bottom});
$shadow-x-small-dark--center: var(--shadow-x-small-dark--center, #{$_shadow-x-small-dark--center});
$shadow-x-small-dark--left: var(--shadow-x-small-dark--left, #{$_shadow-x-small-dark--left});
$shadow-x-small-dark--right: var(--shadow-x-small-dark--right, #{$_shadow-x-small-dark--right});

$shadow-small-dark--top: var(--shadow-small-dark--top, #{$_shadow-small-dark--top});
$shadow-small-dark--bottom: var(--shadow-small-dark--bottom, #{$_shadow-small-dark--bottom});
$shadow-small-dark--center: var(--shadow-small-dark--center, #{$_shadow-small-dark--center});
$shadow-small-dark--left: var(--shadow-small-dark--left, #{$_shadow-small-dark--left});
$shadow-small-dark--right: var(--shadow-small-dark--right, #{$_shadow-small-dark--right});

$shadow-dark--top: var(--shadow-dark--top, #{$_shadow-dark--top});
$shadow-dark--bottom: var(--shadow-dark--bottom, #{$_shadow-dark--bottom});
$shadow-dark--center: var(--shadow-dark--center, #{$_shadow-dark--center});
$shadow-dark--left: var(--shadow-dark--left, #{$_shadow-dark--left});
$shadow-dark--right: var(--shadow-dark--right, #{$_shadow-dark--right});

$shadow-regular-dark--top: var(--shadow-regular-dark--top, #{$_shadow-regular-dark--top});
$shadow-regular-dark--bottom: var(--shadow-regular-dark--bottom, #{$_shadow-regular-dark--bottom});
$shadow-regular-dark--center: var(--shadow-regular-dark--center, #{$_shadow-regular-dark--center});
$shadow-regular-dark--left: var(--shadow-regular-dark--left, #{$_shadow-regular-dark--left});
$shadow-regular-dark--right: var(--shadow-regular-dark--right, #{$_shadow-regular-dark--right});

$shadow-medium-soft-dark--top: var(
    --shadow-medium-soft-dark--top,
    #{$_shadow-medium-soft-dark--top}
);
$shadow-medium-soft-dark--bottom: var(
    --shadow-medium-soft-dark--bottom,
    #{$_shadow-medium-soft-dark--bottom}
);
$shadow-medium-soft-dark--center: var(
    --shadow-medium-soft-dark--center,
    #{$_shadow-medium-soft-dark--center}
);
$shadow-medium-soft-dark--left: var(
    --shadow-medium-soft-dark--left,
    #{$_shadow-medium-soft-dark--left}
);
$shadow-medium-soft-dark--right: var(
    --shadow-medium-soft-dark--right,
    #{$_shadow-medium-soft-dark--right}
);

$shadow-medium-dark--top: var(--shadow-medium-dark--top, #{$_shadow-medium-dark--top});
$shadow-medium-dark--bottom: var(--shadow-medium-dark--bottom, #{$_shadow-medium-dark--bottom});
$shadow-medium-dark--center: var(--shadow-medium-dark--center, #{$_shadow-medium-dark--center});
$shadow-medium-dark--left: var(--shadow-medium-dark--left, #{$_shadow-medium-dark--left});
$shadow-medium-dark--right: var(--shadow-medium-dark--right, #{$_shadow-medium-dark--right});

$shadow-large-soft-dark--top: var(--shadow-large-soft-dark--top, #{$_shadow-large-soft-dark--top});
$shadow-large-soft-dark--bottom: var(
    --shadow-large-soft-dark--bottom,
    #{$_shadow-large-soft-dark--bottom}
);
$shadow-large-soft-dark--center: var(
    --shadow-large-soft-dark--center,
    #{$_shadow-large-soft-dark--center}
);
$shadow-large-soft-dark--left: var(
    --shadow-large-soft-dark--left,
    #{$_shadow-large-soft-dark--left}
);
$shadow-large-soft-dark--right: var(
    --shadow-large-soft-dark--right,
    #{$_shadow-large-soft-dark--right}
);

$shadow-large-dark--top: var(--shadow-large-dark--top, #{$_shadow-large-dark--top});
$shadow-large-dark--bottom: var(--shadow-large-dark--bottom, #{$_shadow-large-dark--bottom});
$shadow-large-dark--center: var(--shadow-large-dark--center, #{$_shadow-large-dark--center});
$shadow-large-dark--left: var(--shadow-large-dark--left, #{$_shadow-large-dark--left});
$shadow-large-dark--right: var(--shadow-large-dark--right, #{$_shadow-large-dark--right});

$radius--small: var(--radius--small, #{$_radius--small});
$radius: var(--radius, #{$_radius});
$radius--large: var(--radius--large, #{$_radius--large});

$space--xxx-large: var(--space--xxx-large, #{$_space--xxx-large});
$space--xx-large: var(--space--xx-large, #{$_space--xx-large});
$space--x-large: var(--space--x-large, #{$_space--x-large});
$space--large: var(--space--large, #{$_space--large});
$space--small: var(--space--small, #{$_space--small});
$space--x-small: var(--space--x-small, #{$_space--x-small});
$space--xx-small: var(--space--xx-small, #{$_space--xx-small});
$space--xxx-small: var(--space--xxx-small, #{$_space--xxx-small});
$space: var(--space, #{$_space});
$space--medium: var(--space--medium, #{$_space--medium});

$transition-delay: var(--transition-delay, #{$_transition-delay});

$icon-size: var(--icon-size, #{$_icon-size});

$page-max-width: var(--page-max-width, #{$_page-max-width});
$page-max-width--small: var(--page-max-width--small, #{$_page-max-width--small});
$sidebar-width: var(--sidebar-width, #{$_sidebar-width});
$top-nav-bar-height: var(--top-nav-bar-height, #{$_top-nav-bar-height});


///////////////////////////////////////////////////////////////////////////////////
/// 
///////////////////////////////////////////////////////////////////////////////////

$_color-tertiary: 6, 20, 39;

$_color-gray-darkest: 50, 50, 50;
$_color-gray-dark: 70, 70, 70;
$_color-gray-medium: 137, 137, 137;
$_color-gray-semi-light: 222, 222, 232;
$_color-gray-light: 242, 242, 242;

$_color-tertiary--contrast: 255, 255, 255;

$_font-size--1: remify(36px);
$_font-size--2: remify(24px);
$_font-size--3: remify(20px);
$_font-size--4: remify(16px);
$_font-size--5: remify(14px);
$_font-size--6: remify(12px);

///////////////////////////////////////////////////////////////////////////////////

$color-tertiary: var(--color-tertiary, #{$_color-tertiary});
$color-tertiary--contrast: var(--color-tertiary--contrast, #{$_color-tertiary--contrast});

$color-gray-light: var(--color-gray-light, #{$_color-gray-light});
$color-gray-medium: var(--color-gray-medium, #{$_color-gray-medium});
$color-gray-dark: var(--color-gray-dark, #{$_color-gray-dark});
$color-gray-semi-light: var(--color-gray-semi-light, #{$_color-gray-semi-light});
$color-gray-darkest: var(--color-gray-darkest, #{$_color-gray-darkest});

$font-size--1: var(--font-size--1, #{$_font-size--1});
$font-size--2: var(--font-size--2, #{$_font-size--2});
$font-size--3: var(--font-size--3, #{$_font-size--3});
$font-size--4: var(--font-size--4, #{$_font-size--4});
$font-size--5: var(--font-size--5, #{$_font-size--5});
$font-size--6: var(--font-size--6, #{$_font-size--6});

